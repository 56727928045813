import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../base/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../app/pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../base/assets/ts/_utils'
import {WithChildren} from '../base/helpers'
import BuilderPageWrapper from '../app/pages/layout-builder/BuilderPageWrapper'
import { UserList } from '../app/pages/user/UserList'
import { NewDemandList } from '../app/pages/demand/NewDemandList'
import { DemandStatusList } from '../app/pages/demand-status/DemandStatusList'
import { AddDemandStatus } from '../app/pages/demand-status/AddDemandStatus'
import { DemandTypeList } from '../app/pages/demand-type/DemandTypeList'
import { CallcenterStaffList } from '../app/pages/callcenter-staff/CallcenterStaffList'
import { AddDemandType } from '../app/pages/demand-type/AddDemandType'
import { AddCCStaff } from '../app/pages/callcenter-staff/AddCCStaff'
import { ActiveDemandList } from '../app/pages/demand/ActiveDemandList'
import { HistoryDemandList } from '../app/pages/demand/HistoryDemandList'
import { LatestLogList } from '../app/pages/system/LatestLogList'
import { AddGenericData } from '../app/pages/demand-generic-data/AddGenericData'
import { GenericDataList } from '../app/pages/demand-generic-data/GenericDataList'
import { GenericDataDetail } from '../app/pages/demand-generic-data/GenericDataDetail'
import { UpdateCCStaff } from '../app/pages/callcenter-staff/UpdateCCStaff'
import { ComponentTestPage } from '../app/pages/test/ComponentTestPage'
import { UpdateDemandType } from '../app/pages/demand-type/UpdateDemandType'
import { DemandTypeDetail } from '../app/pages/demand-type/DemandTypeDetail'
import { UserDetail } from '../app/pages/user/UserDetail'
import { AddPackage } from '../app/pages/packages/AddPackage'
import { PackageList } from '../app/pages/packages/PackageList'
import { CCStaffDetail } from '../app/pages/callcenter-staff/CCStaffDetail'
import { EditOrderDemandType } from '../app/pages/demand-type/EditOrderDemandType'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../app/modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../app/modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../app/modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../app/modules/widgets/WidgetsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/user-detail/:id" element={<UserDetail />} />
        <Route path="/new-demand-list" element={<NewDemandList />} />
        <Route path="/active-demand-list" element={<ActiveDemandList />} />
        <Route path="/history-demand-list" element={<HistoryDemandList />} />
        <Route path="/demand-status-list" element={<DemandStatusList />} />
        <Route path="/add-demand-status" element={<AddDemandStatus />} />
        <Route path="/add-demand-type" element={<AddDemandType />} />
        <Route path="/demand-type-list" element={<DemandTypeList />} />
        <Route path="/callcenter-staff-list" element={<CallcenterStaffList />} />
        <Route path="/cc-staff-detail/:id" element={<CCStaffDetail />} />
        <Route path="/add-staff" element={<AddCCStaff />} />
        <Route path="/update-staff/:id" element={<UpdateCCStaff />} />
        <Route path="/latest-log-list" element={<LatestLogList />} />
        <Route path="/generic-data-list" element={<GenericDataList />} />
        <Route path="/add-generic-data" element={<AddGenericData />} />
        <Route path="/generic-data-detail/:id" element={<GenericDataDetail />} />
        <Route path="/demand-type-detail/:id" element={<DemandTypeDetail />} />
        <Route path="/edit-demand-type-order/:id" element={<EditOrderDemandType />} />
        <Route path="/update-demand-type/:type/:id" element={<UpdateDemandType />} />
        <Route path="/add-package" element={<AddPackage />} />
        <Route path="/package-list" element={<PackageList />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
