import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DemandTypeModel } from "../../../models";
import * as Yup from "yup";
import { DemandTypeDataType } from "../../../shared/enum/DemandType/DemandTypeDataType";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import clsx from "clsx";
import React from "react";
import CListBox from "../../../components/CListBox/CListBox";
import { isNullOrEmpty } from "../../../base/helpers";
import { DemandGenericDataService } from "../../../services/demand-generic-data/demand-generic-data-service";

export function UpdateDemandType() {

    const { type, id } = useParams<{ type: string; id: string }>();
    const [demandType, setType] = useState<string>();
    const [selectedIsOptional, setIsOptional] = useState<number>();
    const [demandTypeData, setdemandTypeData] = useState<DemandTypeModel[]>();
    const [fileDocumentType, setFileDocumentType] = useState<boolean>(false);
    const [isDropdown, setDropdownType] = useState<boolean>(false);
    const [controlRules, setControlRules] = useState<boolean>(false);
    const [activeBoxID, setActiveBoxID] = useState<any[]>([1]);
    const [activeBoxData, setActiveBoxData] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState("1");

    const [loading, setLoading] = useState(false);
    const [serviceError, setError] = useState<string>();
    const navigate = useNavigate();

    const initialValues: DemandTypeModel = {
        id: Number(id),
        title: "",
        detail: "",
        special_name: "",
        main_special_name: "",
        data_type: "string",
        valid_data_type: [],
        regexp: "",
        is_optional: false,
        created_date: new Date(),
        updated_date: new Date(),
        version: 1,
    };

    const [data, setData] = useState<DemandTypeModel>(initialValues);
    const { getDemandTypeById, updateDemandType } = DemandTypeService();
    const { createGenericData, getAllLastGenericData, getSubRelatedDataById } = DemandGenericDataService();

    const profileDetailsSchema = Yup.object().shape({
        title: Yup.string().required("Başlık Bilgisi Gereklidir!"),
        detail: Yup.string().required("Açıklama Bilgisi Gereklidir!"),
    });

    useEffect(() => {
        setType(type);

        const getAllDemandType = async (id: any) => {
            const data = await getDemandTypeById({ "id": id });
            setData(data);

            if (type === 'main') {
                formik.setFieldValue('title', data.title);
                formik.setFieldValue('detail', data.detail);
            } else if (type === 'sub') {
                formik.setFieldValue('title', data.title);
                formik.setFieldValue('detail', data.detail);
                formik.setFieldValue('data_type', data.data_type);
                formik.setFieldValue('is_optional', data.is_optional);
            }
        };
        getAllDemandType(id);

    }, [id, type]);


    const formik = useFormik<DemandTypeModel>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            setSubmitting(false);
            setTimeout(() => {
                const updatedData = Object.assign(data, values);
                setData(updatedData);
                setLoading(false);
            }, 1000);

            try {
                let dataType;

                switch (parseInt(values.data_type ?? '0')) {
                    case DemandTypeDataType.Number:
                        dataType = "number";
                        break;
                    case DemandTypeDataType.String:
                        dataType = "string";
                        break;
                    case DemandTypeDataType.Boolean:
                        dataType = "boolean";
                        break;
                    case DemandTypeDataType.File:
                        dataType = "file";
                        break;
                    case DemandTypeDataType.Dropdown:
                        dataType = "dropdown";
                        break;
                    default:
                        break;
                }

                values.data_type = dataType;

                //Ana Talepse boş gitmeli.
                if (demandType === "main") {
                    values.main_special_name = undefined;
                }

                const result = await updateDemandType(values);
                if (result) {
                    setData(initialValues);
                    navigate('/demand-type-list');
                } else {
                    setError("Tip Güncelleme Başarısız!");
                }
            } catch (e: any) {
                console.error('e: ', e);
                setError(e.message);
            }
        },
    });

    const selectChange = (e: any) => {
        formik.setFieldValue('main_special_name', e.target.value);
        formik.setFieldValue('special_name', e.target.value);
    };

    const selectChangeDataType = (e: any) => {
        const gelenDataType = parseInt(e.target.value);
        let dataType;

        if (gelenDataType === DemandTypeDataType.File) {
            setFileDocumentType(true);
            setDropdownType(false);
            formik.setFieldValue('generic_data_id', null);
        } else if (gelenDataType === DemandTypeDataType.Dropdown) {
            setDropdownType(true);
            setFileDocumentType(false);
        } else {
            setDropdownType(false);
            setFileDocumentType(false);
            formik.setFieldValue('generic_data_id', null);
        }

        // Formik handleChange fonksiyonunu çağırarak formik'e seçilen değeri güncelletiyoruz.
        formik.handleChange(e);
        formik.setFieldValue('data_type', gelenDataType);
    };

    const handleClosedClick = (e: any) => {
        console.log('closed: ', e);
        if (!isNullOrEmpty(e) && e > 0) {
            setActiveBoxID(prev => {
                const updatedIds = prev.filter((id, index) => e > index);
                return updatedIds;
            });
            // setActiveBoxData(prevData =>
            //     prevData.filter(item => item.id !== id)
            // );
        }
    };

    const getSubRelatedData = async (id: number) => {
        try {
            const response = await getSubRelatedDataById(1, 1, { "id": id });
            console.log("response|getSubRelatedDataById:", response);
            setActiveBoxData(prevData => ({
                ...prevData,
                [id]: response
            }));
        } catch (e: any) {
            setError(e);
            console.error("Error fetching data:", e);
        }
    };

    const handleItemClick = (item: any, listBoxIndex: number) => {
        console.log("Tıklanan öğe:", item);
        console.log("Tıklanan listBoxIndex:", listBoxIndex);
        const newId = item.id;

        formik.setFieldValue('generic_data_id', newId);

        if (!activeBoxID.includes(newId)) {
            setActiveBoxID(prev => {
                prev[listBoxIndex + 1] = item.id
                return prev.filter((id, index) => listBoxIndex + 1 >= index);
            });
            console.log('ActiveBoxID: ', activeBoxID);
            getSubRelatedData(newId);
        }
    };

    const selectChangeControlRules = (e: any) => {
        formik.setFieldValue('regexp', e.target.value);
    };

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Talep Tipi Güncelle
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                </div>

                <div className="collapse show card-footer">
                    <form
                        onSubmit={formik.handleSubmit}
                        noValidate
                        className="form"
                        id="create_demand_type"
                    >
                        <div className="card-body py-3"></div>

                        {demandType === "main" &&
                            <>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Talep Başlık:
                                    </label>

                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <input
                                                    type="text"
                                                    placeholder={data?.title}
                                                    {...formik.getFieldProps("title")}
                                                    className={clsx(
                                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.title && formik.errors.title,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.title && !formik.errors.title,
                                                        }
                                                    )}
                                                />
                                                {formik.touched.title && formik.errors.title && (
                                                    <div className="fv-plugins-message-container">
                                                        <span role="alert">{formik.errors.title}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Detay Bilgi:
                                    </label>

                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <textarea
                                                    placeholder={data?.detail}
                                                    rows={4}
                                                    {...formik.getFieldProps("detail")}
                                                    className={clsx(
                                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.detail &&
                                                                formik.errors.detail,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.detail &&
                                                                !formik.errors.detail,
                                                        }
                                                    )}
                                                />
                                                {formik.touched.detail && formik.errors.detail && (
                                                    <div className="fv-plugins-message-container">
                                                        <span role="alert">{formik.errors.detail}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {demandType === "sub" &&
                            <>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Alt Tip Başlık:
                                    </label>

                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <input
                                                    type="text"
                                                    placeholder="TC Kimlik No"
                                                    {...formik.getFieldProps("title")}
                                                    className={clsx(
                                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.title && formik.errors.title,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.title && !formik.errors.title,
                                                        }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Alt Tip Detaylar:
                                    </label>

                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <textarea
                                                    placeholder="ör: TC Kimlik numarası bilgisi resmi kurumlar için gereklidir."
                                                    rows={4}
                                                    {...formik.getFieldProps("detail")}
                                                    className={clsx(
                                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.detail && formik.errors.detail,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.detail && !formik.errors.detail,
                                                        }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Veri Tipi:
                                    </label>

                                    <div className="col-lg-4 fv-row">
                                        <select
                                            {...formik.getFieldProps("data_type")}
                                            onChange={selectChangeDataType}
                                            className={clsx(
                                                "form-select form-select-solid form-select-lg",
                                                {
                                                    "is-invalid":
                                                        formik.touched.data_type && formik.errors.data_type,
                                                },
                                                {
                                                    "is-valid":
                                                        formik.touched.data_type && !formik.errors.data_type,
                                                }
                                            )}
                                        >
                                            <option value={DemandTypeDataType.None}>Seçiniz</option>
                                            <option value={DemandTypeDataType.Number}>Sayı</option>
                                            <option value={DemandTypeDataType.String}>Metin</option>
                                            <option value={DemandTypeDataType.File}>Dosya</option>
                                            <option value={DemandTypeDataType.Dropdown}>Veri</option>
                                        </select>

                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block"></div>
                                        </div>
                                    </div>
                                </div>

                                {isDropdown && (
                                    <>
                                        <div style={{
                                            overflowX: 'scroll',
                                            whiteSpace: 'nowrap',
                                            width: '100%',
                                            display: 'flex'
                                        }}>
                                            {activeBoxID.map((item, index) => {
                                                console.log('activeBoxData: ', activeBoxData)
                                                console.log('item: ', item)
                                                console.log('aaa1: ', activeBoxData[item], ' item: ', item, 'index: ', index)
                                                return (
                                                    <React.Fragment key={`active-box-${index}`}>

                                                        {activeBoxData[item] &&

                                                            <CListBox key={`${activeBoxData[item]?.id}-${index}-${index}`}
                                                                item={activeBoxData[item]}
                                                                listBoxIndex={index}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: '10px',
                                                                    minWidth: '300px',
                                                                    height: '300px'
                                                                }}
                                                                onItemClick={handleItemClick}
                                                                onClosedClick={() => handleClosedClick(index)}
                                                            >
                                                            </CListBox>

                                                        }

                                                        {(activeBoxData[item] === undefined || activeBoxData[item] === null) &&
                                                            (<>
                                                                <CListBox key="Boş" title="Boş" item={item}>

                                                                </CListBox>
                                                            </>)
                                                        }

                                                    </React.Fragment>
                                                )
                                            })
                                            }

                                        </div>
                                    </>
                                )}

                                {fileDocumentType && <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        İzin Verilen Doküman Tipleri:
                                    </label>

                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <input
                                                    type="text"
                                                    placeholder=".pdf, .docx, .xls"
                                                    {...formik.getFieldProps("valid_data_type")}
                                                    className={clsx(
                                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.valid_data_type && formik.errors.valid_data_type,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.valid_data_type && !formik.errors.valid_data_type,
                                                        }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {controlRules && <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Kontrol Edilecek Kurallar:
                                    </label>

                                    <div className="col-lg-4 fv-row">
                                        <select
                                            {...formik.getFieldProps("regexp")}
                                            value={selectedValue}
                                            onChange={selectChangeControlRules}
                                            className={clsx(
                                                "form-select form-select-solid form-select-lg",
                                                {
                                                    "is-invalid":
                                                        formik.touched.regexp && formik.errors.regexp,
                                                },
                                                {
                                                    "is-valid":
                                                        formik.touched.regexp && !formik.errors.regexp,
                                                }
                                            )}
                                        >
                                            <option value="12">
                                                Sayı Kontrol (11 rakamdan büyük olamaz)
                                            </option>
                                            <option value="22">
                                                Tarih Kontrol (Doğum tarihi 2000 den büyük olmalıdır!)
                                            </option>
                                        </select>

                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block"></div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                        Zorunlu Alan
                                    </label>

                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <div className="form-check form-switch form-check-custom form-check-solid">
                                                    <input
                                                        id="flexSwitchDefault"
                                                        type="checkbox"
                                                        {...formik.getFieldProps("is_optional")}
                                                        value={selectedIsOptional}
                                                        className={clsx(
                                                            "form-check-input h-25px w-12px",
                                                            {
                                                                "is-invalid":
                                                                    formik.touched.is_optional && formik.errors.is_optional,
                                                            },
                                                            {
                                                                "is-valid":
                                                                    formik.touched.is_optional && !formik.errors.is_optional,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                            >
                                {!loading && "Güncelle"}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{ display: "block" }}
                                    >
                                        Lütfen Bekleyiniz...{" "}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                    <div>
                        {serviceError && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">Hata: {serviceError}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}