export const OperationStatusMessages = {
    Success: "Successed",
    BadRequest: "Bad Request",
    UnAuthorization: "UnAuthorization",
    EmailOrPasswordWrong: "Email/Password info wrong.",
    InvalidEmailOrPassword: "Invalid email or password",
    UnknowError: "Unknow Error",
    AuthHeaderMissing: "Authorization header is missing",
    NotFound: "Not found",
    InvalidToken: "Invalid Token"
}