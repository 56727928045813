import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthInit } from './app/modules/auth';
import { I18nProvider } from './base/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from './base/layout/core';
import { MasterInit } from './base/layout/MasterInit';

function App() {
  return (
    <div>
      <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
    </div>
  );
}

export default App;

