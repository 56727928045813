import { useEffect, useState } from "react";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import CTable from "../../../components/CTable/CTable";
import { StaffModel } from "../../../models";
import { StaffService } from "../../../services/staff/staff.service";
import { Icons } from "../../../shared/constant/icon/Icons";
import { CustomButtonProps } from "../../../components/CTable/model/CustomButtonProps";
import { TableColors } from "../../../shared/constant/color/TableColors";

export function CallcenterStaffList() {
  let [data, setData] = useState<StaffModel[]>();
  const { getAllStaff, deleteStaff, deactiveUser, reactiveUser } = StaffService();

  const columns: TableColumn<StaffModel>[] = [
    { label: 'Adı', key: 'name', width: 110 },
    { label: 'Soyadı', key: 'surname', width: 110 },
    { label: 'Email', key: 'email', width: 120 },
    { label: 'Telefon', key: 'phone', width: 130 },
    { label: 'Rolü', key: 'role_id', width: 120 },
    { label: 'Dil', key: 'language', width: 120 },
    { label: 'Tanımlanma Zamanı', key: 'created_date_text', width: 120 },
    { label: 'Güncellenme Zamanı', key: 'updated_date_text', width: 120 },
    { label: 'Hesap Aktif', key: 'is_active_text', width: 120, colorkey: "color", bold: true },
    { label: 'Aksiyon', key: undefined, width: 130 },
  ];

  const tableOptions = {
    header: 'Çağrı Merkezi Personel Listesi',
    description: 'Çağrı Merkezi Personel Listesi',
    columns: columns,
    data: data,
    icon: Icons.ArrowRight,
    navigateURL: "cc-staff-detail",
    navigateKey: "id"
  };

  const customButtonOptions: CustomButtonProps[] = [
    { name: 'Aktifleştir', action: (id) => { reactiveForUser(id); } },
    { name: 'Pasifleştir', action: (id) => { deactiveForUser(id); } },
    { name: 'Sil', action: (id) => { deleteForUser(id); } },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllStaff();
        response.map((item: any) => {
          const createDateObject = new Date(item["created_date"]);
          const updateDateObject = new Date(item["updated_date"]);
          item["created_date_text"] = createDateObject.toLocaleString();
          item["updated_date_text"] = updateDateObject.toLocaleString();
          item["is_active_text"] = item.is_active ? 'Aktif' : 'Pasif';
          let color = item.is_active ? TableColors.Primary : TableColors.Danger;
          item.color = color;
        });
        setData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const reactiveForUser = async (id: number) => {
    try {

      if (id) {
        const response = await reactiveUser({ userId: id });

        if (response) {
          const updatedData = data?.map((item) => {
            if (item.id === id) {
              return { ...item, is_active_text: 'Aktif', color: TableColors.Primary, is_active: true };
            }
            return item;
          });

          setData(updatedData);
        } else {
          console.warn("reactive İşlemi Başarısız!");
        }
      }

    } catch (e: any) {
      console.error("Error reactiveForUser: ", e);
    }
  };

  const deactiveForUser = async (id: number) => {
    try {

      if (id) {
        const response = await deactiveUser({ userId: id });

        if (response) {
          const updatedData = data?.map((item) => {
            if (item.id === id) {
              return { ...item, is_active_text: 'Pasif', color: TableColors.Danger, is_active: false };
            }
            return item;
          });

          setData(updatedData);
        } else {
          console.warn("deactive İşlemi Başarısız!");
        }
      }

    } catch (e: any) {
      console.error("Error deactiveForUser: ", e);
    }
  };

  const deleteForUser = async (id: number) => {
    try {

      if (id) {
        const response = await deleteStaff({ id: id });

        if (response && response.count && response.count > 0) {
          data = data?.filter(x => x.id !== id);
          setData(data);
        } else {
          console.warn("Silme İşlemi Başarısız!");
        }
      }

    } catch (e: any) {
      console.error("Error deleteForUser: ", e);
    }
  };

  return (
    <>
      <CTable columns={columns} data={data} options={tableOptions} customButtons={customButtonOptions}></CTable>
    </>
  )
}