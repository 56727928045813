import { useEffect, useState } from "react";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import CTable from "../../../components/CTable/CTable";
import { UserService } from "../../../services/user/user.service";
import { Icons } from "../../../shared/constant/icon/Icons";
import { UserModel } from "../../../models/UserModel";
import { CustomButtonProps } from "../../../components/CTable/model/CustomButtonProps";
import { TableColors } from "../../../shared/constant/color/TableColors";
import { SearchProps } from "../../../components/CTable/model/SearchProps";

export function UserList() {

    let [data, setData] = useState<UserModel[]>();
    const { getAllUsers, reactiveUser, deactiveUser } = UserService();

    const columns: TableColumn<UserModel>[] = [
        { label: 'Adı', key: 'name', width: 100 },
        { label: 'Soyadı', key: 'surname', width: 100 },
        { label: 'Email', key: 'email', width: 120 },
        { label: 'Tanımlanma Zamanı', key: 'created_date_text', width: 120 },
        { label: 'Güncellenme Zamanı', key: 'updated_date_text', width: 120 },
        { label: 'Hesap Aktif', key: 'is_active_text', width: 120, colorkey: "color", bold: true },
        { label: 'Aksiyon', key: undefined, width: 130 },
    ];

    const searchOptions: SearchProps = {
        title: "Kullanıcı Ara"
    };

    const tableOptions = {
        header: 'Mobil Kullanıcı Listesi',
        description: 'Mobil Kullanıcı Listesi',
        columns: columns,
        data: data,
        icon: Icons.ArrowRight,
        navigateURL: "user-detail",
        navigateKey: "id",
        deleteRowName: "name",
        searchOptions: searchOptions
    };

    const customButtonOptions: CustomButtonProps[] = [
        { name: 'Aktifleştir', action: (id) => { reactiveForUser(id); } },
        { name: 'Pasifleştir', action: (id) => { deactiveForUser(id); } },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAllUsers();
                response.map((item: any) => {
                    const createDateObject = new Date(item["created_date"]);
                    const updateDateObject = new Date(item["updated_date"]);
                    item["created_date_text"] = createDateObject.toLocaleString();
                    item["updated_date_text"] = updateDateObject.toLocaleString();
                    item["is_active_text"] = item.is_active ? 'Aktif' : 'Pasif';
                    let color = item.is_active ? TableColors.Primary : TableColors.Danger;
                    item.color = color;
                });
                setData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const reactiveForUser = async (id: number) => {
        try {

            if (id) {
                const response = await reactiveUser({ userId: id });

                if (response) {
                    const updatedData = data?.map((item) => {
                        if (item.id === id) {
                            return { ...item, is_active_text: 'Aktif', color: TableColors.Primary, is_active: true };
                        }
                        return item;
                    });

                    setData(updatedData);
                } else {
                    console.warn("reactive İşlemi Başarısız!");
                }
            }

        } catch (e: any) {
            console.error("Error reactiveForUser: ", e);
        }
    };

    const deactiveForUser = async (id: number) => {
        try {

            if (id) {
                const response = await deactiveUser({ userId: id });

                if (response) {
                    const updatedData = data?.map((item) => {
                        if (item.id === id) {
                            return { ...item, is_active_text: 'Pasif', color: TableColors.Danger, is_active: false };
                        }
                        return item;
                    });

                    setData(updatedData);
                } else {
                    console.warn("deactive İşlemi Başarısız!");
                }
            }

        } catch (e: any) {
            console.error("Error deactiveForUser: ", e);
        }
    };

    return (
        <>
            <CTable columns={columns} data={data} options={tableOptions} customButtons={customButtonOptions}></CTable>
        </>
    )
}