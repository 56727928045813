import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";

export function EditOrderDemandType() {


    const { id } = useParams();
    const { getAllDemandTypeById, updateDemandTypeOrder } = DemandTypeService();
    const [demandTypeList, setDemandTypeList] = useState<any>([]);


    const handleOnDragEnd = async (result: any) => {
        console.log('result: ', result);
        if (!result.destination) return;
        const reorderedList = Array.from(demandTypeList);
        const [removed] = reorderedList.splice(result.source.index, 1);
        reorderedList.splice(result.destination.index, 0, removed);
        setDemandTypeList(reorderedList);

        const orderData = reorderedList.map((item: any, index) => ({
            id: item.id,
            order: index + 1, // Yeni sıralama bilgisi (1'den başlatıyoruz)
        }));

        
        await updateDemandTypeOrder({ "orderData": orderData });
    };


    useEffect(() => {
        const getAllDemandType = async (id: any) => {
            const data = await getAllDemandTypeById({ "main_special_id": id });
            data.sort((a: any, b: any) => a?.order - b?.order);
            setDemandTypeList(data);
        };
        getAllDemandType(id);

    }, []);

    function convertDataTypeText(dataType: string) {
        let convertedText;

        switch (dataType) {
            case "file":
                convertedText = "Dosya";
                break;
            case "string":
                convertedText = "Metin";
                break;
            case "number":
                convertedText = "Sayı";
                break;
            case "dropdown":
                convertedText = "Veri";
                break;
            default:
                break;
        }

        return convertedText;
    };

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Talep Tipi Detay Sıralaması
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                </div>
                <div className="collapse show card-footer">
                    <div className="card-body py-3">
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="droppable-list">
                                {(provided) => (
                                    <ul
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{ padding: "0", listStyleType: "none" }}
                                    >
                                        {demandTypeList.map((item: any, index: number) => (
                                           <>
                                           <p>ID: {item.id}</p>
                                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            padding: "8px",
                                                            margin: "4px 0",
                                                            backgroundColor: "#f4f4f4",
                                                            border: "1px solid #ccc",
                                                            cursor: "move",
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        <p>
                                                            {index + 1}-)
                                                            {item?.title}
                                                            | <b>{convertDataTypeText(item?.data_type)}</b>
                                                        </p>
                                                    </li>
                                                )}
                                            </Draggable>
                                            </>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>

        </>
    );
}
