import { ServiceResponse, StaffModel } from "../../models";
import { OperationStatusMessages } from "../../shared/constant/OperationStatusMessages";
import useProxyManager from "../core/useProxyManager"

export const StaffService = () => {

    const { httpGet, httpPost } = useProxyManager();

    const _basePath = "cms/staff/";

    const getAllStaff = async (): Promise<[]> => {
        const data = await httpGet(_basePath + "all/");

        //TODO: helper a alınacak
        if (data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getStaffById = async (reqData: any): Promise<StaffModel> => {
        const data = await httpPost(_basePath + "info", reqData);

        //TODO: helper a alınacak
        if (data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data.data;
    };


    //todo: model eklendikten sonra any yerine model eklenecek
    const createStaff = async (reqData: any): Promise<any> => {
        const response = await httpPost(_basePath + "new", reqData);

        if (response === undefined || response === null) {//todo: ortak bi mekanizmaya alınacak!!!
            throw new Error('Bilinmeyen Hata! Kayıt Bulunamadı!');
        }

        return response;
    };

    const deleteStaff = async (reqData: any): Promise<ServiceResponse> => {
        const response = await httpPost(_basePath + "delete", reqData);

        //TODO: helper a alınacak
        if (response === undefined || response === null || response.status !== 200) {
            throw new Error('Not Found')
        }

        return response;
    };

    const reactiveUser = async (reqData: any): Promise<boolean> => {
        const result = await httpPost(_basePath + "reactive", reqData);

        //TODO: helper a alınacak
        if (result === undefined || result === null) {
            throw new Error('Not Found')
        }

        return result.messages === OperationStatusMessages.Success ? true : false;
    };

    const deactiveUser = async (reqData: any): Promise<boolean> => {

        const result = await httpPost(_basePath + "deactive", reqData);

        //TODO: helper a alınacak
        if (result === undefined || result === null) {
            throw new Error('Not Found')
        }

        return result.messages === OperationStatusMessages.Success ? true : false;
    };

    return {
        getAllStaff,
        getStaffById,
        createStaff,
        deleteStaff,
        reactiveUser,
        deactiveUser
    };
};

