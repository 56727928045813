import { UserModel } from "../../models/UserModel";
import { OperationStatusMessages } from "../../shared/constant/OperationStatusMessages";
import useProxyManager from "../core/useProxyManager"

export const UserService = () => {

    const { httpGet, httpPost } = useProxyManager();

    const _basePath = "cms/mobile-user/";

    const getAllUsers = async (): Promise<UserModel[]> => {
        const data = await httpGet(_basePath + "all");

        //TODO: helper a alınacak
        if (data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getUserById = async (reqData: any): Promise<UserModel> => {
        const data = await httpPost(_basePath + "info", reqData);

        //TODO: helper a alınacak
        if (data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const reactiveUser = async (reqData: any): Promise<boolean> => {
        const result = await httpPost(_basePath + "reactive", reqData);

        //TODO: helper a alınacak
        if (result === undefined || result === null) {
            throw new Error('Not Found')
        }

        return result.messages === OperationStatusMessages.Success ? true : false;
    };

    const deactiveUser = async (reqData: any): Promise<boolean> => {

        const result = await httpPost(_basePath + "deactive", reqData);

        //TODO: helper a alınacak
        if (result === undefined || result === null) {
            throw new Error('Not Found')
        }

        return result.messages === OperationStatusMessages.Success ? true : false;
    };

    return {
        getAllUsers,
        getUserById,
        reactiveUser,
        deactiveUser
    };
};

