import { useEffect, useState } from "react";
import CTab from "../../../components/CTab/CTab";
import { TabColumn } from "../../../components/CTab/model/TabColumn";
import { TabProps } from "../../../components/CTab/model/TabProps";
import CTabItem from "../../../components/CTabItem/CTabItem";
import { useParams } from "react-router-dom";
import { isNullOrEmpty } from "../../../base/helpers";
import { StaffModel } from "../../../models";
import { StaffService } from "../../../services/staff/staff.service";
import { StaffLanguages } from "../../../shared/enum/Staff/StaffLanguages";


export function CCStaffDetail() {

    const tabColumns: TabColumn[] = [
        { label: "Personel Bilgileri", text: "Tab text1" },
    ];

    const tabOptions = {
        title: "Personel Tüm Bilgileri",
    };

    const tabProps: TabProps = {
        columns: tabColumns,
        options: tabOptions,
    };

    // const { reqstaffData } = useParams<StaffModel>();
    const { id } = useParams();
    const { getStaffById } = StaffService();
    const [staffData, setData] = useState<StaffModel>();
    let role = isNullOrEmpty(staffData) ? '' : staffData?.role_id === 1 ? 'Çağrı Merkezi Personeli' : 'Diğer';

    useEffect(() => {
        /**
         * TODO: Burada redux ile taşınıp api çağırımı iptal edilecek!!!
         */

        const getStaffInfo = async (id: any) => {
            const data = await getStaffById({ "userId": id });
            setData(data);
            role = isNullOrEmpty(staffData) ? '' : staffData?.role_id === 1 ? 'Çağrı Merkezi Personeli' : 'Diğer';
        };

        getStaffInfo(id);
    }, [id]);

    const formatDateTime = (dateString: any) => {
        if (!isNullOrEmpty(dateString)) {
            const date = new Date(dateString);
            if (!isNaN(date.getTime())) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                const hours = String(date.getHours()).padStart(2, "0");
                const minutes = String(date.getMinutes()).padStart(2, "0");
                return `${year}-${month}-${day}T${hours}:${minutes}`;
            }
        }
        return "";
    };

    return (
        <>
            <CTab props={tabProps}>
                <CTabItem key="tab-kullanici-bilgileri">
                    <div className="card-body py-3">

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Aktiflik Bilgisi:
                            </label>
                            <div className="col-lg-9 mt-3">
                                <div className="row">
                                    <div className="col-lg-3 fv-row">
                                        <div
                                            className={`${'mb-3 mb-lg-0 fw-bolder' + ' ' + (staffData?.is_active ? 'badge badge-primary' : 'badge badge-danger')}`}
                                        >
                                            {staffData?.is_active ? 'Aktif' : 'Pasif'}
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Adı:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={staffData?.name ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="surname"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Soyadı:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="surname"
                                            disabled
                                            value={staffData?.surname ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Email Adresi:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={staffData?.email ?? ''}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Telefon:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={staffData?.phone ?? '-'}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Rolü:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={role ?? '-'}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Dil:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <select
                                            id="language"
                                            value={staffData?.language}
                                            disabled
                                            className="form-select form-select-solid form-select-lg mb-3 mb-lg-0"
                                        >
                                            <option value={StaffLanguages.None} label="Seçiniz" />
                                            <option value={StaffLanguages.Turkish}>Türkçe</option>
                                            <option value={StaffLanguages.English}>
                                                İngilizce
                                            </option>
                                        </select>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Üyelik Kayıt Tarihi:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="datetime-local"
                                            id="created_date"
                                            disabled
                                            value={formatDateTime(staffData?.created_date) ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Son Güncellenme Tarihi:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="datetime-local"
                                            id="created_date"
                                            disabled
                                            value={formatDateTime(staffData?.updated_date) ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CTabItem>
                <CTabItem key="tab-kullanici-bilgileri2">

                </CTabItem>
            </CTab>
        </>
    )

}