import { useEffect, useState } from "react";
import CTab from "../../../components/CTab/CTab";
import { TabColumn } from "../../../components/CTab/model/TabColumn";
import { TabProps } from "../../../components/CTab/model/TabProps";
import CTabItem from "../../../components/CTabItem/CTabItem";
import { UserModel } from "../../../models/UserModel";
import { useParams } from "react-router-dom";
import { UserService } from "../../../services/user";
import { isNullOrEmpty } from "../../../base/helpers";


export function UserDetail() {

    const tabColumns: TabColumn[] = [
        { label: "Kullanıcı Bilgileri", text: "Tab text1" },
    ];

    const tabOptions = {
        title: "Kullanıcının Tüm Bilgileri",
    };

    const tabProps: TabProps = {
        columns: tabColumns,
        options: tabOptions,
    };

    // const { reqUserData } = useParams<UserModel>();
    const { id } = useParams();
    const { getUserById } = UserService();
    const [userData, setData] = useState<UserModel>();

    useEffect(() => {
        /**
         * TODO: Burada redux ile taşınıp api çağırımı iptal edilecek!!!
         */

        const getUserInfo = async (id: any) => {
            const data = await getUserById({ "userId": id });
            setData(data);
        };

        getUserInfo(id);
    }, [id]);

    const formatDateTime = (dateString: any) => {
        if (!isNullOrEmpty(dateString)) {
            const date = new Date(dateString);
            if (!isNaN(date.getTime())) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                const hours = String(date.getHours()).padStart(2, "0");
                const minutes = String(date.getMinutes()).padStart(2, "0");
                return `${year}-${month}-${day}T${hours}:${minutes}`;
            }
        }
        return "";
    };

    return (
        <>
            <CTab props={tabProps}>
                <CTabItem key="tab-kullanici-bilgileri">
                    <div className="card-body py-3">

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Aktiflik Bilgisi:
                            </label>
                            <div className="col-lg-9 mt-3">
                                <div className="row">
                                    <div className="col-lg-3 fv-row">
                                        <div
                                            className={`${'mb-3 mb-lg-0 fw-bolder' + ' ' + (userData?.is_active ? 'badge badge-primary' : 'badge badge-danger')}`}
                                        >
                                            {userData?.is_active ? 'Aktif' : 'Pasif'}
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Adı:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={userData?.name ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="surname"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Soyadı:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="surname"
                                            disabled
                                            value={userData?.surname ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Email Adresi:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={userData?.email ?? ''}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Telefon:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={userData?.phone ?? '-'}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Adres:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={userData?.address ?? '-'}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Kimlik Numarası:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="number"
                                            id="name"
                                            disabled
                                            value={userData?.identity_number?.toString() ?? '-'}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Pasaport Numarası:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={userData?.passport_number ?? '-'}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Dil:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="text"
                                            id="name"
                                            disabled
                                            value={userData?.language ?? ''}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Üyelik Tarihi:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="datetime-local"
                                            id="created_date"
                                            disabled
                                            value={formatDateTime(userData?.created_date) ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                htmlFor="name"
                                className="col-lg-2 col-form-label fw-bold fs-6"
                            >
                                Son Güncellenme Tarihi:
                            </label>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-6 fv-row">
                                        <input
                                            type="datetime-local"
                                            id="created_date"
                                            disabled
                                            value={formatDateTime(userData?.updated_date) ?? ""}
                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CTabItem>
                <CTabItem key="tab-kullanici-bilgileri2">

                </CTabItem>
            </CTab>
        </>
    )

}