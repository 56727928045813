import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DemandTypeModel } from "../../../models";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { DemandTypeDataType } from "../../../shared/enum/DemandType/DemandTypeDataType";
import { DemandGenericDataService } from "../../../services/demand-generic-data/demand-generic-data-service";
import { isNullOrEmpty } from "../../../base/helpers";

export function AddPackage() {

    const initialValues: DemandTypeModel = {
        title: "",
        detail: "",
        special_name: "",
        main_special_name: "",
        data_type: "string",
        valid_data_type: [],
        regexp: "",
        is_optional: false,
        created_date: new Date(),
        updated_date: new Date(),
        version: 1,
    };

    //Hooks
    const [demandTypeData, setdemandTypeData] = useState<DemandTypeModel[]>();
    const { getAllLastDemandType } = DemandTypeService();
    const { createGenericData, getAllLastGenericData, getSubRelatedDataById } = DemandGenericDataService();

    const [selectedValue, setSelectedValue] = useState("1");
    const [selectedIsOptional, setIsOptional] = useState<number>();

    const [formData, setformData] = useState<DemandTypeModel>();
    const [serviceError, setError] = useState<string>();

    const { createDemandType } = DemandTypeService();
    const [data, setData] = useState<DemandTypeModel>(initialValues);
    const [loading, setLoading] = useState(false);
    const [activeBoxData, setActiveBoxData] = useState<any[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        //setIsOptional(0);
    }, []);

    const profileDetailsSchema = Yup.object().shape({
        title: Yup.string().required("Başlık Bilgisi Gereklidir!"),
        detail: Yup.string().required("Açıklama Bilgisi Gereklidir!"),
    });


    const formik = useFormik<DemandTypeModel>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            setSubmitting(false);
            setTimeout(() => {
                const updatedData = Object.assign(data, values);
                setData(updatedData);
                setLoading(false);
            }, 1000);

            try {
                let dataType;

                switch (parseInt(values.data_type ?? '0')) {
                    case DemandTypeDataType.Number:
                        dataType = "number";
                        break;
                    case DemandTypeDataType.String:
                        dataType = "string";
                        break;
                    case DemandTypeDataType.Boolean:
                        dataType = "boolean";
                        break;
                    case DemandTypeDataType.File:
                        dataType = "file";
                        break;
                    case DemandTypeDataType.Dropdown:
                        dataType = "dropdown";
                        break;
                    default:
                        break;
                }

                values.data_type = dataType;

                //Ana Talepse boş gitmeli.
                if (selectedValue === "1") {
                    values.main_special_name = undefined;
                }

                const result = await createDemandType(values);
                if (result) {
                    setData(initialValues);
                    navigate('/demand-type-list');
                } else {
                    setError("Tip Ekleme Başarısız!");
                }
            } catch (e: any) {
                console.error('e: ', e);
                setError(e.message);
            }
        },
    });



    return (
        <>
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Yeni Paket Tipi Ekle
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                </div>
                <div className="card-body py-3">
                    <div className="row mb-6">
                        <label className="col-lg-2 col-form-label required fw-bold fs-6">
                            Eklenecek Paket Tipi
                        </label>

                        <div className="col-lg-2 fv-row">
                            <select
                                className="form-select form-select-solid form-select-lg"
                                value={selectedValue}
                            >
                                <option value="1">Ana Paket</option>
                                <option value="2">Alt Paket</option>
                            </select>

                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="collapse show card-footer">
                    <form
                        onSubmit={formik.handleSubmit}
                        noValidate
                        className="form"
                        id="create_demand_type"
                    >
                        <div className="card-body py-3">
                            {selectedValue === "1" && (
                                <>
                                    <div className="row mb-6">
                                        <label className="col-lg-2 col-form-label required fw-bold fs-6">
                                            Paket Başlık:
                                        </label>

                                        <div className="col-lg-8">
                                            <div className="row">
                                                <div className="col-lg-6 fv-row">
                                                    <input
                                                        type="text"
                                                        placeholder="Denklik Başvurusu"
                                                        {...formik.getFieldProps("title")}
                                                        className={clsx(
                                                            "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                            {
                                                                "is-invalid":
                                                                    formik.touched.title && formik.errors.title,
                                                            },
                                                            {
                                                                "is-valid":
                                                                    formik.touched.title && !formik.errors.title,
                                                            }
                                                        )}
                                                    />
                                                    {formik.touched.title && formik.errors.title && (
                                                        <div className="fv-plugins-message-container">
                                                            <span role="alert">{formik.errors.title}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-6">
                                        <label className="col-lg-2 col-form-label required fw-bold fs-6">
                                            Paket Bilgi:
                                        </label>

                                        <div className="col-lg-8">
                                            <div className="row">
                                                <div className="col-lg-6 fv-row">
                                                    <textarea
                                                        placeholder="Denklik Başvurusu ...."
                                                        rows={4}
                                                        {...formik.getFieldProps("detail")}
                                                        className={clsx(
                                                            "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                                            {
                                                                "is-invalid":
                                                                    formik.touched.detail &&
                                                                    formik.errors.detail,
                                                            },
                                                            {
                                                                "is-valid":
                                                                    formik.touched.detail &&
                                                                    !formik.errors.detail,
                                                            }
                                                        )}
                                                    />
                                                    {formik.touched.detail && formik.errors.detail && (
                                                        <div className="fv-plugins-message-container">
                                                            <span role="alert">{formik.errors.detail}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                >
                                    {!loading && "Ekle"}
                                    {loading && (
                                        <span
                                            className="indicator-progress"
                                            style={{ display: "block" }}
                                        >
                                            Lütfen Bekleyiniz...{" "}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div>
                        {serviceError && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">Hata: {serviceError}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}